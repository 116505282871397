import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { TrashIcon, DocumentIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@/shared/contexts/auth-context';
import { useDeleteFile, useProcessFile } from '../stores/unprocessedFilesStore';

export type DropResult = {
  id: string;
  clientId: string;
  containerId: string | undefined;
  deleteFile: boolean;
  handled: boolean | undefined;
  onProcessed: () => void;
};

function Card({ id, name, url }: { id: string; name: string; url: string }) {
  const navigate = useNavigate();
  const processFile = useProcessFile();
  const deleteFile = useDeleteFile();
  const urlPrefix = useLocation().pathname.startsWith('/clients') ? 'clients' : 'prospects';

  const [keep, setKeep] = useState(false);
  const [processed, setProcessed] = useState(false);

  const [_, drag] = useDrag(
    () => ({
      type: ItemTypes.FILE,
      item: { id, name, deleteFile: !keep },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      }),
      end: async (item, monitor) => {
        if (monitor.didDrop()) {
          const dropResult = monitor.getDropResult<DropResult>();
          if (dropResult && dropResult?.handled !== true) {
            await processFile.executeAsync(item.id, dropResult.clientId, dropResult.containerId, item.deleteFile);
            if (item.deleteFile) {
              setProcessed(true);
            }
            if (dropResult.containerId) {
              navigate(`/${urlPrefix}/${dropResult.clientId}/documents/${dropResult.containerId}`);
            } else {
              navigate(`/${urlPrefix}/${dropResult.clientId}/documents`);
            }
          }
        }
      }
    }),
    [keep]
  );

  const onDelete = async () => {
    const { success } = await deleteFile.executeAsync(id);
    if (success) {
      setProcessed(true);
    }
  };

  if (processed) return null;

  return (
    <div className="rounded-lg bg-white shadow p-4 flex space-x-2 content-center" ref={drag}>
      <div className="flex-1">
        <a href={url} target="_blank" className="flex items-center font-bold w-64 hover:underline" rel="noreferrer">
          <DocumentIcon className="h-8 w-8 mr-1" /> <span className="truncate">{name}</span>
        </a>
        <p className="text-gray-500 text-sm mt-2">
          <label className="flex items-center ml-1">
            <input
              type="checkbox"
              checked={keep}
              onChange={() => setKeep(!keep)}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-2"
            />
            Keep here for now
          </label>
        </p>
      </div>
      <div className="flex-shrink-0 flex flex-col justify-between space-y-3">
        <button
          type="button"
          onClick={onDelete}
          className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="sr-only">Delete</span>
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={() => setProcessed(true)}
          className="inline-flex h-5 w-5 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <span className="sr-only">Skip</span>
          <ArrowRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}

export const ItemTypes = {
  FILE: 'file'
};

function Toast() {
  const {
    user: { unprocessedDocuments }
  } = useAuth();

  const [items, setItems] = useState(unprocessedDocuments);

  useEffect(() => {
    setItems(unprocessedDocuments);
  }, [unprocessedDocuments]);

  if (!items) {
    return null;
  }

  return (
    <div className="stack fixed bottom-10 right-10">
      {items.map((item: any) => {
        return <Card key={item.id} id={item.id} name={item.name} url={item.url} />;
      })}
    </div>
  );
}

export default Toast;
