import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import api from '../data/api';
import authenticationApi from '@/features/authentication/data/api';

interface AuthContextInterface {
  user: any;
  logout: () => Promise<void>;
  isFetched: boolean;
  reloadUser: () => void;
  hasRole: (role: string | string[]) => boolean;
}

export const AuthContext = React.createContext<AuthContextInterface>({} as AuthContextInterface);

function AuthProvider(props: any) {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  const { data, isFetched, refetch } = useQuery(
    ['me', isAuthenticated],
    async () => {
      return api
        .me()
        .then((data) => {
          if (!isAuthenticated) setIsAuthenticated(true);
          return data;
        })
        .catch((error) => {
          if (error.containsCode('AccessDenied')) {
            setIsAuthenticated(false);
          }
          return null;
        });
    },
    {
      enabled: isAuthenticated || isAuthenticated == null,
      refetchInterval: 10000
    }
  );

  const logout = () => {
    return authenticationApi
      .signOut()
      .then(() => navigate('/'))
      .finally(() => setIsAuthenticated(false));
  };

  const reloadUser = async () => {
    await refetch();
  };

  const hasRole = useCallback(
    (roles: string | string[]) => {
      const role = data?.role;
      if (role && ([] as string[]).concat(roles).includes(role)) {
        return true;
      }

      return false;
    },
    [data?.role]
  );

  const value = React.useMemo<any>(() => {
    return {
      user: isAuthenticated ? { ...data, isAuthenticated: true } : { isAuthenticated: false },
      logout,
      isFetched,
      reloadUser,
      hasRole
    };
  }, [isAuthenticated, data, data?.unprocessedDocuments, isFetched]);

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
