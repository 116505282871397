import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from '@/App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './shared/contexts/auth-context';
import { AppDataProvider } from './shared/contexts/appData-context';

// import { makeServer } from './mirage';
// import { PersonalClient } from '@/__tests__/models/clients';

// const env = process.env.NODE_ENV;
// if (env === 'development') {
//   const server = makeServer({ environment: env });
//   server.create('client', PersonalClient);
// }

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppDataProvider>
          <App />
        </AppDataProvider>
      </AuthProvider>
    </QueryClientProvider>
  </Router>
);

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
//   reportWebVitals(console.log);
// }
