import React, { Suspense } from 'react';
import '@/styles/main.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useAuth } from '@/shared/contexts/auth-context';
import Spinner from './shared/components/svg/Spinner';
import Toast from './features/incoming-files/components/Toast';

const AuthenticatedApp = React.lazy(() => import('@/AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(() => import('@/UnauthenticatedApp'));

function App() {
  const { user, isFetched } = useAuth();

  if (user.isAuthenticated) {
    if (!isFetched) {
      return (
        <div className="flex items-center justify-center h-screen flex-col">
          <Spinner />
        </div>
      );
    }

    return (
      <Suspense>
        <DndProvider backend={HTML5Backend}>
          <AuthenticatedApp />
          <Toast />
        </DndProvider>
      </Suspense>
    );
  }

  return (
    <Suspense>
      <UnauthenticatedApp />
    </Suspense>
  );
}

export default App;
