import { UseMutationResult } from '@tanstack/react-query';

export interface ExecuteAsyncResult<T> {
  result: T | null;
  success: boolean;
  error?: Error;
}

async function executeAsyncHandled<T, TInput>(
  mutation: UseMutationResult<T, unknown, TInput, unknown>,
  input?: any
): Promise<ExecuteAsyncResult<T>> {
  try {
    const result = await mutation.mutateAsync(input);
    return { result, success: true };
  } catch (e: any) {
    // handled else where
    return { result: null, error: e, success: false };
  }
}

export default executeAsyncHandled;
