import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import executeAsyncHandled from '@/shared/utils/mutation-wrapper';
import api from '../data/api';

const defaultMutationOptions = {};

export function useProcessFile() {
  const queryClient = useQueryClient();

  const processFileMutation = useMutation(
    ({ fileId, clientId, folderId, deleteFile }: { fileId: string; clientId: string; folderId?: string; deleteFile: boolean }) =>
      api.processFile(fileId, clientId, folderId, deleteFile),
    {
      onSuccess: ({ clientId }: { clientId: string; folderId?: string }) => {
        queryClient.invalidateQueries(['document', clientId]);
      },
      ...defaultMutationOptions
    }
  );

  const value = useMemo(() => {
    return {
      isLoading: processFileMutation.isLoading,
      error: processFileMutation.error,
      executeAsync: (fileId: string, clientId: string, folderId: string | undefined, deleteFile: boolean) =>
        executeAsyncHandled(processFileMutation, { fileId, clientId, folderId, deleteFile })
    };
  }, [processFileMutation.isLoading, processFileMutation.error]);

  return value;
}

export function useDeleteFile() {
  const queryClient = useQueryClient();

  const deleteFileMutation = useMutation(({ fileId }: { fileId: string }) => api.delete(fileId), {
    ...defaultMutationOptions
  });

  const value = useMemo(() => {
    return {
      isLoading: deleteFileMutation.isLoading,
      error: deleteFileMutation.error,
      executeAsync: (fileId: string) => executeAsyncHandled(deleteFileMutation, { fileId })
    };
  }, [deleteFileMutation.isLoading, deleteFileMutation.error]);

  return value;
}
