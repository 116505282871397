import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from './auth-context';
import api from '../data/api';
import SelectListItem from '../models/SelectListItem';

interface AppDataContextInterface {
  advisors: SelectListItem[];
  users: SelectListItem[];
  taskListTypes: SelectListItem[];
  productProviders: SelectListItem[];
  productInvestmentTypes: SelectListItem[];
  riskProfiles: SelectListItem[];
  serviceLevels: SelectListItem[];
  roles: string[];
  isFetched: boolean;
}

export const AppDataContext = React.createContext<AppDataContextInterface>({} as AppDataContextInterface);

function AppDataProvider(props: any) {
  const { user } = useAuth();

  const { data, isFetched } = useQuery(
    ['app-data', user.isAuthenticated],
    () => {
      return api.getAppData();
    },
    {
      enabled: user.isAuthenticated,
      refetchInterval: 10000
    }
  );

  const value = React.useMemo(() => {
    return data
      ? {
          taskListTypes: data.taskListTypes,
          productProviders: data.productProviders,
          productInvestmentTypes: data.productInvestmentTypes,
          riskProfiles: data.riskProfiles,
          serviceLevels: data.serviceLevels,
          roles: data.roles,
          users: data.users.map((u: { id: string; name: string; role: string }) => ({ text: u.name, value: u.id })),
          advisors: data.users
            .filter((u: { id: string; name: string; role: string }) => u.role === 'Advisor')
            .map((u: { id: string; name: string; role: string }) => ({ text: u.name, value: u.id })),
          isFetched
        }
      : {
          roles: [],
          users: [],
          advisors: [],
          productProviders: [],
          isFetched
        };
  }, [data, isFetched]);

  return <AppDataContext.Provider value={value} {...props} />;
}

function useAppData() {
  const context = React.useContext(AppDataContext);
  if (context === undefined) {
    throw new Error(`useAppData must be used within a AppDataProvider`);
  }
  return context;
}

export { AppDataProvider, useAppData };
