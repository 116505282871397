import axios from '@/shared/services/axios-instance';

const api = {
  getLookup: (lookupType: string, query: string) =>
    axios
      .get(`/lookups/${lookupType}?s=${query}`)
      .then((response) => response.data)
      .catch(() => []),
  me: () => axios.get('/account/me').then((response) => response.data),
  getAppData: () => axios.get('/app-data').then((response) => response.data),
  search: (query: string) =>
    axios
      .get(`/search?s=${query}`)
      .then((response) => response.data)
      .catch(() => [])
};

export default api;
