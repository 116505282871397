import axios from '@/shared/services/axios-instance';

export interface SignInRequest {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface MfaSignInRequest {
  authenticatorCode: string;
  rememberMe: boolean;
  rememberMachine: boolean;
}

const api = {
  signIn: (data: SignInRequest) => {
    return axios.post('/auth/sign-in', data).then((response) => response.data);
  },
  mfaSignIn: (data: MfaSignInRequest) => {
    return axios.post('/auth/sign-in-2fa', data).then((response) => response.data);
  },
  signOut: () => axios.post('/auth/sign-out')
};

export default api;
