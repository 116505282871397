import axios from '@/shared/services/axios-instance';

const api = {
  delete: (fileId: string) => {
    return axios.delete(`/unprocesseddocuments/${fileId}`).then((res) => res.data);
  },
  processFile: (fileId: string, clientId: string, folderId: string | undefined, deleteFile: boolean) => {
    return axios
      .put(`/unprocesseddocuments/${fileId}`, { clientId, folderId, delete: deleteFile })
      .then((response) => ({ id: response.data, clientId, folderId }));
  }
};

export default api;
