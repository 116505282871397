import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  withCredentials: true,
  paramsSerializer(params) {
    return qs.stringify(params, { indices: false });
  }
});

export class ApiError extends Error {
  constructor(public errors: { code: string; description: string }[]) {
    super();
  }

  containsCode(code: string): boolean {
    return this.errors.find((e) => e.code === code) != null;
  }
}

function getXSRFToken() {
  return getCookie('XSRF-TOKEN');
}

function getCookie(name: string): string {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || ''
  );
}

function isStateChangingVerb(method?: string): boolean {
  if (!method) return true;
  return ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method.toUpperCase());
}

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (isStateChangingVerb(config.method)) {
    const xsrfToken = getXSRFToken();
    if (xsrfToken && config.headers) {
      config.headers['X-XSRF-TOKEN'] = xsrfToken;
    }
  }
  return config;
};

const onResponseError = (error: AxiosError<any>): Promise<AxiosError> => {
  if (error instanceof AxiosError) {
    if (error.code === 'ERR_NETWORK') {
      return Promise.reject(
        new ApiError([
          {
            code: 'NetworkError',
            description: 'Opps! We could not connect, please check your internet connection.'
          }
        ])
      );
    }

    if (error.response?.status === 404) {
      return Promise.reject(
        new ApiError([
          {
            code: 'NotFound',
            description: 'Opps! We could not find that action.'
          }
        ])
      );
    }

    if (error.response?.status === 401) {
      return Promise.reject(
        new ApiError([
          {
            code: 'AccessDenied',
            description: 'Access denied'
          }
        ])
      );
    }

    return Promise.reject(new ApiError(error.response?.data));
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest, undefined);
axiosInstance.interceptors.response.use(undefined, onResponseError);

export default axiosInstance;
